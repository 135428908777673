Cake.create('content_layout_1','#content_layout_1',{
    root:'.content-layout-container',
    handlers:{
        destroy(e){
            this.reset();
        },
        async isConnected(e){
            // aboutus-content

            let aboutUs = await this.fire('getAboutUs');
            if(aboutUs){

                // let container = this.container['aboutus-content'];
                // console.log(container);
                // container.innerHTML = `
                // <h3>ABOUT US</h3>
                // <br>
                // <p>Welcome to Lucky888Bet! </p>
                // <br>
                // <p>
                // If you love the casino atmosphere but not the casino crowd, this is the perfect place for you. Our online games combine the best of both worlds, merging the excitement of the traditional casino and bingo games, with the convenience of your online gambling sites.
                // Lucky888Bet offers a wide range of different games from different software providers which is great as you get to experience different games from each provider on one platform. You only need to put your identification and payment information once and never again instead of putting it on many different online casino sites.
                // We are the 1st Filipino gaming company certified by the Philippine Amusement and Gaming Corporation (PAGCOR) that has developed remote eBingo gaming machines and bingo games certified by BMM Test Labs and developed Lucky888bet as the first gaming platform approved by the Game Laboratory International (GLI) being a REMOTE UNIFIED GAMING PLATFORM.
                // </p>

                // `;
            }

        }
    },
    subscribe:{},
});