const router = new Cake.Router({
    '/':{
        name:'main_page',
        display:'Main Page',
        components:['main_page','banner_top','game_category','game_list_tab','game_list','banner_bottom','content_layout_1','footer_1'],
        auth:false,
    },
    '/login':{
        name: 'login',
        display:'Login',
        components:['auth_page', 'login_form','footer_1'],
        auth:false,
    },
    '/register':{
        name: 'register',
        display:'Register',
        components:['auth_page', 'prekyc_form','footer_1'],
        auth:false,
    },
    '/confirm-otp':{
        name: 'confirm_otp',
        display:'Confirm OTP',
        components:['auth_page', 'otp_form','footer_1'],
        auth:false,
    },
    '/kyc-completed':{
        name: 'kyc_completed',
        display:'KYC Completed',
        components:['auth_page', 'kyc_completed','footer_1'],
        auth:false,
    },
    '/kyc':{
        name: 'kyc_form',
        display:'KYC Form',
        components:['auth_page', 'kyc_form','footer_1'],
        auth:false,
    },

    '/profile/my-account':{
        name: 'my_account',
        display:'My Account',
        components:['profile','profile_sidebar','profile_quick','my_account','footer_1'],
        auth:true,
    },
    '/profile/deposit/history':{
        name: 'deposit_history',
        display:'Deposit History',
        components:['profile','table','footer_1'],
        auth:true,
    },

    '/profile/deposit/wallet/gcash':{
        name: 'deposit-gcash',
        display:'Deposit GCash',
        components:['profile','deposit_gcash','footer_1'],
        auth:true,
    },
    '/profile/deposit/wallet':{
        name: 'deposit',
        display:'Deposit',
        components:['profile','deposit','footer_1'],
        auth:true,
    },
    '/profile/deposit':{
        name: 'deposit_page',
        display:'Deposit',
        components:['profile','profile_sidebar','profile_quick','profile_deposit','footer_1'],
        auth:true,
    },
    '/profile/withdraw/history':{
        name: 'withdraw_history',
        display:'Withdraw History',
        components:['profile','table','footer_1'],
        auth:true,
    },
    '/profile/withdraw/wallet':{
        name: 'withdraw',
        display:'Withdraw',
        components:['profile','withdraw','footer_1'],
        auth:true,
    },
    '/profile/withdraw':{
        name: 'withdraw_page',
        display:'Withdraw',
        components:['profile','profile_sidebar','profile_quick','profile_withdraw','footer_1'],
        auth:true,
    },
    '/profile':{
        name: 'profile',
        display:'Profile',
        components:['profile','profile_sidebar','profile_quick','footer_1'],
        auth:true,
    },
    '/gcash/payment-success':{
        name: 'gcash_payment_success',
        display:'Gcash Payment Success',
        components:['gcash_payment_status'],
        auth:true,
    },
    '/gcash/payment-failed':{
        name: 'gcash_payment_failed',
        display:'Gcash Payment Failed',
        components:['gcash_payment_status'],
        auth:true,
    },

    '404':function(){
        return '/';
    },
},{
    auth:{
        '401':'main_page',
        valid:{
            // admin:'dashboard',
            player:'profile',
        },
        verify:['api','verify'],
    },
    components:{
        sidebar:{
            rerender:['/',]
        },
        profile_sidebar:{
            rerender:[
                '/',
                '/profile',
                '/profile/deposit/wallet',
                '/profile/deposit/wallet/gcash',
                '/profile/withdraw/wallet',
                '/profile/deposit/history',
                '/profile/withdraw/history'
            ]
        },
        profile:{
            rerender:['/profile','/']
        },
        footer_1:{
            rerender:['/','/confirm-otp','/login','/register']
        }
    }
});