Cake.create('spinner', '#spinner', {
    root:'BODY',
    handlers:{
        destroy(id){
            // clearTimeout(this.data.timeout);
            return new Promise((res, rej)=>{
                setTimeout(()=>{
                    this.reset({id}).then(()=>{
                        res();
                    }).catch(err=>{
                        res();
                    });
                },3000);
            });
        },
        click(e){
            this.fire.destroy();
        },
        isConnected(e){
            let {emit:{level}} = e;
            // console.log('spinner is connected');
            level && this.container.spinner && (this.container.spinner.style.zIndex = level);

            setTimeout(()=>{
                if(this.data.still){
                    this.fire.destroy();
                    this.data.still = false;
                };
            }, 5000);

            this.data.still = true;
        }
    },
    subscribe:{
        spin:{
            components:['api','table','form','axios','plant','kyc_form','header'],
            async handler(l){
                let {level, id} = l;
                await this.renderQue({id,emit:{level}});
                // console.trace();
            }
        },
        spinout:{
            components:['api','table','form','axios','plant','kyc_form','header'],
            async handler(id){
                await this.fire.destroy(id);
            }
        },
        axios:{
            spinProgress(perc){
                this.$scope.set('progress', perc);
            }
        }
    },
});