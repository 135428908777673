Cake.create('app','#app',{
    handlers:{
        async isConnected(){
            let res = await this.fire('getProfile');
            if(res.status){
                this.data.user = res.data;
                this.fire('refreshHeader');
                this.fire('refreshQuickProfile');
            };
        }
    },
    subscribe:{
        getProfileUser:{
            components:['my_account','header', 'header_profile','profile_quick'],
            handler(e){
                return this.data.user;
            }
        }
    },
});