Cake.create('alert',null,{
    handlers:{
        notify(e){
            return new Promise((res)=>{
                iziToast[e.ctx]({
                    timeout: 2000000,
                    close: false,
                    overlay: true,
                    displayMode: 'once',
                    zindex: 999,
                    title: e.title,
                    layout:2,
                    message: e.message,
                    position: 'center',
                    buttons: [
                        ['<button><b>OK</b></button>', function (instance, toast) {
                 
                            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
                 
                        }, true],
                    ],
                    onClosing: function(instance, toast, closedBy){
                        // console.info('Closing | closedBy: ' + closedBy);
                    },
                    onClosed: function(instance, toast, closedBy){
                        // console.info('Closed | closedBy: ' + closedBy);
                        res();
                    }
                });
            })
        },
        alert(e){
            return new Promise((res)=>{
                iziToast[e.ctx]({
                    // timeout: 2000000,
                    close: false,
                    overlay: false,
                    displayMode: 'once',
                    zindex: 999,
                    layout:1,
                    title: e.title,
                    message: e.message,
                    position: 'bottomRight',
                    timeout:3000,
                    buttons: [
                        ['<button><b>OK</b></button>', function (instance, toast) {
                 
                            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
                 
                        }, true],
                    ],
                    onClosing: function(instance, toast, closedBy){
                        // console.info('Closing | closedBy: ' + closedBy);
                    },
                    onClosed: function(instance, toast, closedBy){
                        // console.info('Closed | closedBy: ' + closedBy);
                        res();
                    }
                });
            })
        }
    },
    subscribe:{
        error:{
            components:['api','otp_form','deposit','axios','login_form', 'withdraw'],
            handler(e){
                console.trace();
                e.ctx = 'error';
                e.title = e.title || 'Error';
                return this.fire.notify(e, false);
            }
        },
        success:{
            components:['api','kyc_form','otp_form','deposit','header','login_form', 'withdraw'],
            handler(e){
                e.ctx = 'success';
                e.title = e.title || 'Success';
                return this.fire.notify(e, false);
            }
        },
        info:{
            components:['api','prekyc_form','deposit','login_form', 'withdraw'],
            handler(e){
                e.ctx = 'info';
                e.title = e.title || 'Info';
                return this.fire.notify(e, false);
            }
        },
        alertInfo:{
            components:['api','prekyc_form','deposit', 'withdraw','socket'],
            handler(e){
                e.ctx = 'info';
                return this.fire.alert(e, false);
            }
        },
        alertSuccess:{
            components:['api','prekyc_form','deposit', 'withdraw'],
            handler(e){
                e.ctx = 'success';
                return this.fire.alert(e, false);
            }
        },
        alertError:{
            components:['api','prekyc_form','deposit', 'withdraw'],
            handler(e){
                e.ctx = 'error';
                return this.fire.alert(e, false);
            }
        },
    },
});