Cake.create('socket', null, {
    role:'model',
    init(){
        window.socket = io.connect('http://localhost:9823/player',{
            withCredentials:true,
            'sync disconnect on unload':true,
            transports:['websocket']
        });
        socket.on("connect", () => {
            console.log(socket.id);
        });

        socket.on('deposit-approve',(d)=>{
            this.fire('alertInfo',{
                title:'Deposit Update',
                message:'your deposit has been approved.'
            });
            this.fire('getBalance');
        });

        socket.on('withdraw-approve',(d)=>{
            this.fire('alertInfo',{
                title:'Withdraw Update',
                message:'your withdraw has been approved.'
            });
            this.fire('getBalance');
        });
    },
    handlers:{},
    subscribe:{},
})