Cake.create('login_form','#login_form',{
    root:'.auth-form-container',
    role:'form',
    watch:true,
    validate:{
        member_id(e){
            this.fire.activeControl(e, false);

        },
        password(e){
            this.fire.activeControl(e, false);
        }
    },
    handlers:{
        async destroy(e){
            await this.reset();
        },
        isConnected(){},
        async submit(e){
            let json = this.utils.toFormData(e.target, {json:true});
            // await this.fire('spin');
            // await this.fire('spinout');

            // console.log(json);
            const prelogin = await this.fire('prelogin',json);

            if(prelogin && prelogin.status){
                this.fire('info',{message:'We have sent an otp confirmation in your mobile number.'});
                this.$router.goTo('confirm_otp',{replace:true,params:{type:'login'}});
            } else {
                this.fire('error',{message:'something went wrong.'});
            }
        },
        activeControl(e){
            let target = e.target;
            let value = target.value;
            if(value != ""){
                if(!target.classList.contains('active')){
                    target.classList.add('active')
                }
            } else {
                if(target.classList.contains('active')){
                    target.classList.remove('active')
                }
            };
        }
    },
    subscribe:{},
})