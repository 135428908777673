Cake.create('otp_form','#otp_form',{
    root:'.auth-form-container',
    data(e){

    },
    animate:{
        otp_form:{
            render:{velocity:{opacity:1}},
        },
    },
    router(){
        this.data.state = this.$router.state;
    },
    handlers:{
        async destroy(){
            await this.reset();
        },
        isConnected(){
            this.data['1'] = document.getElementById('o_1');
            this.data['2'] = document.getElementById('o_2');
            this.data['3'] = document.getElementById('o_3');
            this.data['4'] = document.getElementById('o_4');
            this.data['5'] = document.getElementById('o_5');
            this.data['6'] = document.getElementById('o_6');
            setTimeout(()=>{
                this.data['1'].focus();
            },50);

        },
        async submit(e){
            let target = e.target;
            let json = this.utils.toFormData(target,{json:true});
            let otp = [];
            Object.keys(json).forEach((key, index)=>{
                let v = json[key];
                let [t,n] = key.split('_');
                n = Number(n)-1;
                otp[n] = v;
            });

            otp = otp.join("");


            let confirmed;
            
            
            if(this.data.state && this.data.state.type == 'login'){
                confirmed = await this.fire('confirmLoginOtp',{otp});

                let user = await this.fire('getAuthUser');


            } else {
                confirmed = await this.fire('confirmKycOtp',{otp});
            };
            
            
            if(confirmed){

                await this.fire('success', {message:'otp is confirmed'});
                await this.fire('spin',{id:'resultSubmitOtp'});
                if(this.data.state && this.data.state.type == 'login'){
                    await this.$router.goTo('my_account',{replace:true});
                } else {
                    await this.$router.goTo('kyc_form',{replace:true});
                };
                await this.fire('spinout','resultSubmitOtp');
               
            };
        },
        keydown(e){
            console.log(e); 
        },
        input(e){
            let id = e.target.id;
            let [i, n] = id.split('_');

            n = Number(n) + 1;

            let el = this.data[String(n)];
            
            if(el){
                el.focus();
            } else {
                

            };
            if(e.target.value != ''){
                e.target.value = e.target.value[e.target.value.length -1];     
            }
        },
    },
    subscribe:{},
});