Cake.create('game_list_tab','#game_list_tab',{
    root:'.game-list-tab',
    handlers:{
        async destroy(e){
            await this.reset();
        },
        isConnected(e){

            this.$scope.set('gameListTabs', [
                {url:config.FILE_BASE_URL+'/images/game_tab/popular.png'},
                {url:config.FILE_BASE_URL+'/images/game_tab/bingo-icon-white.png'},
                {url:config.FILE_BASE_URL+'/images/game_tab/slot-machine.png'},
                {url:config.FILE_BASE_URL+"/images/game_tab/dice-icon-white.png"},
                {url:config.FILE_BASE_URL+"/images/game_tab/card-icon-white.png"},
            ]);
        }
    },
    subscribe:{

    }
});