const path = require('path');
const fs = require('fs');

let config = {};

if(process.env.NODE_ENV == 'development'){
    config.API_BASE_URL = process.env.DEVELOPMENT_API_BASE_URL;
    config.FILE_BASE_URL = process.env.DEVELOPMENT_FILE_BASE_URL;
}
if (process.env.NODE_ENV == 'production'){
    config.API_BASE_URL = process.env.PRODUCTION_API_BASE_URL;
    config.FILE_BASE_URL = process.env.PRODUCTION_FILE_BASE_URL;
}
if (process.env.NODE_ENV == 'local'){
    config.API_BASE_URL = process.env.LOCAL_API_BASE_URL;
    config.FILE_BASE_URL = process.env.LOCAL_FILE_BASE_URL;
};

module.exports = config;

