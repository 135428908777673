Cake.create('withdraw','#withdraw',{
    root:'.profile-action',
    role:'form',
    watch:true,
    router(){
        // console.log(this.$router);
    },
    animate:{
        withdraw:{
            render:{velocity:{opacity:1}},
        },
    },
    data(){
        this.controls = [
            {label:'Select Bank',type:'file',field:'withdraw_banks',tag:'select-virtual-update',placeholder:'withraw banks'},
            {label:'Account Number',type:'text',field:'account_number',tag:'input-required',placeholder:'account number'},
            {label:'Account Name',type:'text',field:'account_name',tag:'input-required',placeholder:'account name'},
            {label:'Amount',type:'number',field:'amount',tag:'input-required',placeholder:'amount'},
            {label:'Remarks',type:'text',field:'remarks',tag:'textarea',placeholder:'remarks'},
        ];
        this.withdrawInstructions = [
            {text:'Select a bank.'},
            {text:'Input account details and amount you want to withdraw.'},
            {text:'Click SUBMIT CASHOUT REQUEST button.'},
            {text:'The bank account must be under the name of the requesting player.'},
            {text:'Withdrawal processing time may take 30 minutes up to three (3) banking days.'},
            {text:'You will receive a notification for successful transaction.'},
        ];
        this.notify = [
            'Minimum withdraw of Php 100.00 up to Php 50,000.00'
        ];
        this.title = 'Withdraw';
        this.subtitle = 'Withdraw using your BANK ACCOUNT';
    },
    validate(){},
    handlers:{
        async destroy(){
            await this.fire('removePreview');
            await this.reset();
        },
        async isConnected(){
            let {id, type} = this.$router.state;
            let ref_img_url = "";
            if(id){
                
            };
            this.$scope.set('type',type);
            await this.$scope.set('controls',this.data.controls);

            await this.fire('renderSelectWithdrawBanks',[
                {label:'BPI',value:'1'},
                {label:'BDO',value:'2'},
            ]);


            this.$scope.set('title',this.data.title);
            this.$scope.set('subtitle',this.data.subtitle);
            this.$scope.set('instructions',this.data.withdrawInstructions);

            this.data.notify.forEach(message=>{
                this.fire('alertInfo',{
                    title:'Withdraw',
                    message,
                });
            });
        },
        async submit(e){
            let json = this.utils.toFormData(e.target,{json:false});

            let selectedOptions = await this.fire('selectedOptions');
            
            json.append('settlement_id',selectedOptions.value);


            let created = await this.fire('submitWithdraw',json);
            if(created){
                await this.fire('success',{message:created.message});
                this.$router.goTo('withdraw_page',{replace:true});
            };

        },
        change(e){
            let [file] = e.target.files;


            this.fire('previewChange',file);
        }
    },
    subscribe:{},
});