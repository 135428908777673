Cake.create('kyc_completed','#kyc_completed',{
    root:'.kyc-form-container',
    handlers:{
        async destroy(){
            await this.reset();
        },
        isConnected(e){
            console.log('kyc_completed connected');
        }
    },
    subscribe:{},
});