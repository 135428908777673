Cake.create('game_category','#game_category',{
    root:'.game-category-container',
    init(){

    },
    handlers:{
        isConnected(){

        },
        destroy(e){
            this.reset();
        }
    },
    subscribe:{},
});