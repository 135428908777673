Cake.create('profile_withdraw','#profile_withdraw',{
    root:'.middle-content',
    router(){
        // console.log(this.$router);
    },
    animate:{
        withdraw_page:{
            render:{velocity:{opacity:1}},
        },
    },
    data(){
        this.p =  [
            {display:'Bank Account',ref_name:'bank_account',id:1, icon:config.FILE_BASE_URL+"/images/withdraw/bank-white.png",status:"active", type:'bank'},
        ];
    },
    handlers:{
        async destroy(){
            await this.reset();
        },

        isConnected(){
            const ewallets = this.data.p.filter(item=>{
                return item.type == 'ewallet';
            });
            const banks = this.data.p.filter(item=>{
                return item.type == 'bank';
            });


            ewallets.length && this.$scope.set('ewallets',ewallets);
            banks.length && this.$scope.set('banks',banks);
        },

        click(e){
            let target =e.target;
            let id = target.dataset.id;
            let action = target.dataset.action;

            let p = this.data.p.find(item=>{
                return item.id == id;
            });

            if(action == 'withdraw'){
                this.$router.goTo('withdraw',{params:{id,type:p.ref_name}});
            } else if (action == 'history'){
                this.$router.goTo('withdraw_history',{params:{id,type:p.ref_name}});
            };
        },

    },
    subscribe:{},
});