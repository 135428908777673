Cake.create('preview','#preview',{
    handlers:{
        async destroy(e){
            await this.reset();
        },
        isConnected(e){
            let {emit:data} = e;

            setTimeout(()=>{
                URL.revokeObjectURL(this.container.preview.src);
            },1000);
        },
    },
    subscribe:{
        previewChange:{
            components:['deposit'],
            handler(e){
                try {
                    if(e.type.includes('image')){
                        this.container.preview.src =  URL.createObjectURL(e);
                    }
                } catch(err){};
            }
        },
        renderPreview:{
            components:['deposit'],
            handler(e){
                if(!(e.root)){
                    return;
                };
                this.render({
                    root:e.root,
                })
            }
        },
        removePreview:{
            components:['deposit'],
            handler(e){
                this.fire.destroy();  
            }
        }
    },
});