Cake.create('my_account','#my_account',{
    root:'.middle-content',
    router(){
        // console.log(this.$router);
    },
    handlers:{
        async destroy(){
            await this.reset();
        },
   
        async isConnected(){
            this.fire('refreshHeader');
            let res = await this.fire('getProfileUser');
            
            if(res){

                this.$scope.set('username', res.username || "");
                this.$scope.set('email', res.email || "");
                this.$scope.set('name', res.name || "");
                this.$scope.set('id_number', 'Not Listed');
                this.$scope.set('birth_date', res.birth_date);

            } else {

            }


        }
    },
    subscribe:{},
});