Cake.create('header','#header',{
    handlers:{
        async destroy(){
            await this.reset();
        },
        softReload(){
            console.log('header is soft reloaded');
            this.$router.verify().then(res=>{
                // this.fire('spinout');
                console.log(res);
                if(res.status == 1){
                    this.fire('renderHeaderProfile');
                } else {
                    this.fire('renderHeaderLogin');
                };
            });
        },
        async isConnected(){
            console.log('header is connected');
            


            await this.fire.render();


        },
        async render(){
            let res = await this.fire('getProfileUser');
            if(res){
                await this.fire('removeHeaderLogin');
                await this.fire('renderHeaderProfile',res);
            } else {
                await this.fire('removeHeaderProfile');
                setTimeout(()=>{
                    this.fire('isProfileConnected').then(res=>{
                        
                        !res && this.fire('renderHeaderLogin');
                    })
                },3000);
            };
        },
        async openMenu(e){
            e.target.classList.toggle('active');
            let toggle = e.target.classList.contains('active');
            this.$scope.set('showSidebar', toggle);
            await this.fire('showSidebar',toggle);

            if(toggle){
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'unset';
            }
        },
        async logout(){
            
            let logout = await this.fire('logout');


            if(logout && logout.status){
                await this.fire('spin',{id:'headlogout',level:900});
                await this.fire('success', {message:logout.message || 'successfully logged out'});
                
                setTimeout(async ()=>{
                    this.$router.goTo('main_page',{replace:true});
                    await this.fire('spinout','headlogout');
                }, 3000);
            };

        }
    },
    subscribe:{
        header_profile:{
            logout(){
                this.fire.logout();
            }
        },
        refreshHeader:{
            components:['app'],
            handler(e){
                this.fire.render();
            }
        }
    },
});