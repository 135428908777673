Cake.create('axios',null,{
    role:'model',
    data(){

    },
    handlers:{
        submitKyc(e){
            this.fire('spin',{id:'submitKyc'});
            return axios.create({
                withCredentials: true,
            }).post(`${window.config.API_BASE_URL}/userkyc`,e,{
                headers:{
                    'Content-Type':'multipart/form-data',
                },
            }).then(res=>{
                return res.data;
            }).then(res=>{
                this.fire('spinout','submitKyc');
                return res;
            });
        },
        submitDeposit(e){
            this.fire('spin',{id:'submitDeposit'});
            return axios.create({
                withCredentials: true,
            }).post(`${window.config.API_BASE_URL}/deposit`,e,{
                headers:{
                    'Content-Type':'multipart/form-data',
                },
            }).then(res=>{
                return res.data;
            }).then(res=>{
                this.fire('spinout','submitDeposit');
                if(res.status){
                    return res;
                } else {
                    this.fire('error',{message:res.message || '38 something went wrong'});
                    return null
                }
            });
        },
        submitWithdraw(e){
            this.fire('spin',{id:'submitWithdraw'});
            return axios.create({
                withCredentials: true,
            }).post(`${window.config.API_BASE_URL}/withdraw`,e,{
                headers:{
                    'Content-Type':'multipart/form-data',
                },
            }).then(res=>{
                return res.data;
            }).then(res=>{
                this.fire('spinout','submitWithdraw');
                if(res.status){
                    return res;
                } else {
                    this.fire('error',{message:res.message || '39 something went wrong'});
                    return null
                }
            });
        }
    },
    subscribe:{
        kyc_form:{
            submitKyc(e){
                return this.fire.submitKyc(e);
            }
        },
        deposit:{
            submitDeposit(e){
                return this.fire.submitDeposit(e);
            }
        },
        withdraw:{
            submitWithdraw(e){
                return this.fire.submitWithdraw(e);
            }
        },
    },
});