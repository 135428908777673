Cake.create('auth_page','#auth_page',{
    root:'#main',

    handlers:{
        async destroy(e){
            await this.reset();
        },
        isConnected(){
            
        }
    },
    subscribe:{}
});