Cake.create('plant','#plant',{
    data(){
        this.csrf = 'http://localhost:9823/csrf';
        this.kyc = 'http://localhost:9823/api/userkyc/auth';
        this.prelogin = 'http://localhost:9823/api/user/prelogin';
        this.login = 'http://localhost:9823/api/user/login';
    },
    init(){
        // this.fire.csrf({type:'csrf'},false);
    },
    handlers:{
        // async destroy(){
        //     await this.reset();
        // },
        // isConnected(e){
        //     let {emit:data} = e;

        //     let type = data.type;
        //     let query = data.query || data.cred;

        //     let f = this.container.csrf;
        //     if(!f){
        //         return;
        //     };

        //     if(query){
        //         f.action=this.data[type]+`?${this.utils.toUrlSearchParams(query)}`;
        //     } else {
        //         f.action = this.data[type];
        //     };

        //     return f.submit();
        // },
        // async kyc(e){
        //     await this.fire('spin');
        //     await this.fire.stamp();
        //     await this.render({emit:e}).then(()=>{
        //         setTimeout(()=>{
        //             this.fire('spinout');
        //         },2000);
        //         setTimeout(()=>{
        //             location.reload();
        //         },2100);
        //     });
        // },
        // async prelogin(e){
        //     await this.fire('spin');
        //     await this.fire.stamp();
        //     await this.render({emit:e}).then(()=>{
        //         setTimeout(()=>{
        //             this.fire('spinout');
        //         },2000);
        //         setTimeout(()=>{
        //             this.$router.login({role:'player',token:'ad451231',data:{}});
        //         },2100);
        //     });
        // },
        // async login(e){},
        // async csrf(e){
        //     // await this.fire('spin');
        //     // await this.fire.stamp();
        //     // await this.render({emit:e}).then(()=>{
        //     //     return new Promise((res, rej)=>{
        //     //         setTimeout(()=>{
        //     //             this.fire.destroy().then(()=>{
        //     //                 res();
        //     //             }).catch(err=>{
        //     //                 res();
        //     //             });
        //     //         },1000);
        //     //     }).then(()=>{
        //     //         this.fire('spinout');
        //     //     });
        //     // });
        // },
        // stamp(){
        //     let name = 'app-id';
        //     if(!Cookies.get(name)){
        //         Cookies.set(name, new Date().getTime(), { secure: true, sameSite:'none' });
        //     };
        // },
        // async refreshKyc(){
        //     let kyc = await this.fire('getKyc');
        //     this.fire.plant({type:'kyc',query:kyc}, false);
        // }
    },
    subscribe:{
        // refreshKyc:{
        //     components:['api'],
        //     handler(){
        //         this.fire.refreshKyc(null, false);
        //     }
        // },
        // // login_form:{
        // //     submit(e){
        // //         this.fire.plant({type:'login',cred:e}, false);
        // //     }
        // // },
        // plant:{
        //     components:['kyc_form','login_form','otp_form'],
        //     async handler(e){
        //         return await this.fire[e.type](e);
        //     }
        // },
    },
});