Cake.create('footer_1','#footer_1',{
    root:'.footer-container',
    handlers:{
        async destroy(){
            await this.reset();
        },
        isConnected(){
            this.$scope.set('paymentMethods', [
                {img:config.FILE_BASE_URL+"/images/footer/payment_method/bdo-logo.png"},
                {img:config.FILE_BASE_URL+"/images/footer/payment_method/bpi-logo.png"},
                {img:config.FILE_BASE_URL+"/images/footer/payment_method/gcash-logo.png"},
                {img:config.FILE_BASE_URL+"/images/footer/payment_method/paymaya-logo.png"},
                {img:config.FILE_BASE_URL+"/images/footer/payment_method/unionbank-logo.png"}
            ]);
            
            
            this.$scope.set('footerBottom',[
                {img:config.FILE_BASE_URL+"/images/footer/partners/gamesmart.png"},
                {img:config.FILE_BASE_URL+"/images/footer/games-21-long.png"},
                {img:config.FILE_BASE_URL+"/images/footer/copyright.png"},
                {img:config.FILE_BASE_URL+"/images/footer/partners/netent-logo.png"},
                {img:config.FILE_BASE_URL+"/images/footer/partners/redtiger-logo.png"},
            ]);
        }
    },
    subscribe:{},
});