Cake.create('deposit','#deposit',{
    root:'.profile-action',
    role:'form',
    watch:true,
    router(){
        // console.log(this.$router);
    },
    animate:{
        deposit:{
            render:{velocity:{opacity:1}},
        },
    },
    validate(){},
    handlers:{
        async destroy(){
            await this.fire('removePreview');
            await this.reset();
        },
        async isConnected(){
            let {id, type} = this.$router.state;
            let ref_img_url = "";
            if(id){
                
            };


            if(type == 'gcash'){
                let g = await this.fire('activeGcash');
                if(g){
                    g = g.data;
                    ref_img_url = g.icon;
                    this.$scope.set('pay_mobile',g.number);
                };
            };
            this.$scope.set('type',type);

            await this.$scope.set('controls',[
                {label:'Mobile Number',type:'text',field:'mobile_no',tag:'input-required',placeholder:'mobile number'},
                {label:'Reference Number',type:'text',field:'reference_no',tag:'input-required',placeholder:'reference number'},
                {label:'Amount',type:'number',field:'amount',tag:'input-required',placeholder:'amount'},
                {label:'Upload Receipt',type:'file',field:'receipt',tag:'input-file',placeholder:'upload receipt'},
            ]);

            this.findContainer();
            this.fire('renderPreview',{root:this.container.preview});

            this.$scope.set('pay_id',id);


            this.$scope.set('title','SCAN TO PAY');
            this.$scope.set('subtitle','Reload your wallet thru GCASH');
            this.$scope.set('instructions',[
                {text:'Scan the QR code on the screen or send GCash to any of the numbers provided.'},
                {text:'Complete your transaction. Save the transaction receipt for reference.'},
                {text:'Fill in the deposit details on this page.'},
                {text:'Input the deposit amount and reference number.'},
                {text:'Upload the transaction receipt and click submit. A minimum of P100 is required to be able to play.'},
            ]);


            
            
            let cont = this.container.gcash;

            this.fire('renderSkeleton',{
                root:cont,
                width:'280px',
                height:'320px',
            });

            let img = new Image();
            img.src = ref_img_url;
            img.onload = function(){
                setTimeout(()=>{
                    !!ref_img_url && this.fire('removeSkeleton').then(()=>{
    
                        cont.appendChild(img);
                    }); 
                },5000);

            }.bind(this);


            this.fire('alertInfo',{
                title:'Deposit',
                message:'Minimum deposit of Php 100.00',
            });
        },
        async submit(e){
            let json = this.utils.toFormData(e.target,{json:false});

            let created = await this.fire('submitDeposit',json);
            if(created){
                await this.fire('success',{message:created.message});
                this.$router.goTo('deposit_page',{replace:true});
            };

        },
        change(e){
            let [file] = e.target.files;


            this.fire('previewChange',file);
        }
    },
    subscribe:{},
});