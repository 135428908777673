Cake.create('header_profile','#header_profile',{
    root:'.header-account-info',
    renderqueue:[],
    handlers:{
        async destroy(){
            this.data.isConnected = false;
            await this.reset();
        },
        async isConnected(e){
            let {emit:data} = e;

           this.$scope.set('username',data.username);
           this.$scope.set('balance',data.balance);
           this.data.isConnected = true;
        },
        logout(){
            return true;
        }
    },
    subscribe:{
        header:{
            renderHeaderProfile(e){
                this.render({emit:e});
            },
            removeHeaderProfile(){
                this.fire.destroy();
            },
            isProfileConnected(){
                return this.data.isConnected;
            }
        },
        renderBalance:{
            components:['my_account'],
            handler(e){
                this.$scope.set('balance',e);
            }
        },
        updateBalance:{
            components:['api'],
            handler(e){
                this.$scope.set('balance',e);
            }
        }
    },
});