import config from './config';

window.config = config;


import './router.js';


import './components/app.js';
import './components/select.js';
import './components/socket.js';
import './components/api.js';
import './components/axios.js';
import './components/table.js';
import './components/alert.js';
import './components/spinner.js';
import './components/skeleton.js';
import './components/preview.js';
import "./components/sidebar/sidebar.js";
import './components/header/header.js';
import './components/header/header_login.js';
import './components/header/header_profile.js';
import './components/main_page/main_page.js';
import "./components/main_page/banner_bottom.js";
import "./components/main_page/banner_top.js";
import "./components/main_page/content_layout_1.js";
import "./components/main_page/game_category.js";
import "./components/main_page/game_list.js";
import "./components/main_page/game_list_tab.js";
import "./components/auth_page/index.js";
import "./components/auth_page/plant.js";
import "./components/auth_page/login_form.js";
import "./components/auth_page/prekyc_form.js";
import "./components/auth_page/kyc_form.js";
import "./components/auth_page/step.js";
import "./components/auth_page/otp_form.js";
import "./components/auth_page/kyc_completed.js";
import "./components/profile/profile.js";
import "./components/profile/profile-sidebar.js";
import "./components/profile/profile-quick.js";
import "./components/profile/my-account.js";
import "./components/deposit/deposit_page.js";
import "./components/deposit/deposit-gcash.js";
import "./components/deposit/deposit.js";
import "./components/withdraw/withdraw_page.js";
import "./components/withdraw/withdraw.js";
import "./components/footer/footer_1.js";
