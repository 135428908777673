Cake.create('profile_deposit','#profile_deposit',{
    root:'.middle-content',
    router(){
        // console.log(this.$router);
    },
    animate:{
        deposit_page:{
            render:{velocity:{opacity:1}},
        },
    },
    data(){
        this.p =  [
            {display:'GCash',ref_name:'gcash',id:1, icon:config.FILE_BASE_URL+"/images/deposit/gcash-white.png",status:"active", type:'ewallet'},
            {display:'PayMaya',ref_name:'paymaya',id:2, icon:config.FILE_BASE_URL+"/images/deposit/paymaya-white.png",status:"inactive", type:'ewallet'},
            {display:'BDO',ref_name:'bdo',id:3, icon:config.FILE_BASE_URL+"/images/deposit/bdo-white.png",status:"active", type:'bank'},
            {display:'Union Bank',ref_name:'unionbank',id:4, icon:config.FILE_BASE_URL+"/images/deposit/union-white.png",status:"inactive", type:'bank'},
            {display:'BPI',ref_name:'bpi',id:5, icon:config.FILE_BASE_URL+"/images/deposit/bpi-white.png",status:"inactive", type:'bank'}
        ];
    },
    handlers:{
        async destroy(){
            await this.reset();
        },

        isConnected(){

            this.$scope.set('ewallets',this.data.p.filter(item=>{
                return item.type == 'ewallet';
            }));
            this.$scope.set('banks',this.data.p.filter(item=>{
                return item.type == 'bank';
            }));
        },

        click(e){
            let target =e.target;
            let id = target.dataset.id;
            let action = target.dataset.action;

            let p = this.data.p.find(item=>{
                return item.id == id;
            });

            if(action == 'deposit'){
                if(p.ref_name == 'gcash'){
                    
                    this.$router.goTo('deposit-gcash',{params:{id}});
                } else {
                    this.$router.goTo('deposit',{params:{id,type:p.ref_name}});
                };
            } else if (action == 'history'){
                this.$router.goTo('deposit_history',{params:{id,type:p.ref_name}});
            };
        },

    },
    subscribe:{},
});