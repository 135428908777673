Cake.create('profile_sidebar','#profile_sidebar',{
    root:'.left-container',
    router(){
        // console.log(this.$router);
        // this.toggler.recall('activeSidebarItem');
        this.toggler('activeSidebarItem',`profile-${this.$router.name}`);
        this.$scope.set('activeItem', this.$router.display);
    },
    toggle:{
        activeSidebarItem:{
            ns:`sidebar-item`, cls:'is-active', basis:'data-name',
        },
    },
    handlers:{
        async destroy(){
            await this.reset();
        },
        softReload(){
            this.toggler('activeSidebarItem', `profile-${this.$router.name}`);
            this.$scope.set('activeItem', this.$router.display);
        },
        isConnected(){
            this.toggler('activeSidebarItem', `profile-${this.$router.name}`);
            this.$scope.set('activeItem', this.$router.display);
        },  
        click(e){
            let target = e.target;
            let a = target.parentElement;
            let name = a.dataset.name;
            let href = a.href;
            let dhref = a.dataset.href;

            this.toggler('activeSidebarItem', name);

            if(dhref){
                this.$router.goTo(dhref);
            } else {    
                this.fire.nav(href);
            }
            
        },
        nav(href){
            let a = document.createElement('a');
            a.href = href;
            a.target = '_self';
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    },
    subscribe:{},
});