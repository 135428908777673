Cake.create('select',null,{
    handlers:{
        renderSelectWithdrawBanks(options){
            this.data.virtual = VirtualSelect.init({
                ele:'[data-name=virtual-select-withdraw_banks]',
                zIndex: 99,
                dropboxWrapper: 'body',
                options
            });
        },
    },
    subscribe:{
        withdraw:{
            renderSelectWithdrawBanks(options){

                this.fire.renderSelectWithdrawBanks(options);
            },
            selectedOptions(){
                return this.data.virtual.getSelectedOptions();
            }
        }
    },
});