Cake.create('kyc_form','#kyc_form',{
    root:'.kyc-form-container',
    role:'form',
    // watch:true,
    animate:{
        kyc_form:{
            render:{velocity:{opacity:1}},
        },
    },
    data(){
        this['1'] = {
            column:[
                {title:'Mobile Number',field:'mobile_no', type:'text', tag:'input-required', placeholder:'mobile number ( 0926-xxxxxxx )'},
                {title:'Referral Code',field:'referral_code', type:'text', tag:'input', placeholder:'referral code'},
                {title:'',field:'', type:'', tag:'age-confirmation'}
            ],
            btn:'Register',
        };
        this['2'] = {
            column:[
                {title:'Birth Date',field:'birth_date', type:'date', tag:'input-required', placeholder:'birth date'},
                {title:'First Name',field:'first_name', type:'text', tag:'input-required', placeholder:'first name'},
                {title:'Middle Name',field:'middle_name', type:'text', tag:'input', placeholder:'middle name'},
                {title:'Last Name',field:'last_name', type:'text', tag:'input-required', placeholder:'last name'},
            ],
            btn:'Next',
            tabName:'Personal Information'
        };
        this['3'] = {
            column:[
                {title:'Email',field:'email', type:'email', tag:'input-required', placeholder:'email address'},
                {title:'Current Address',field:'address', type:'text', tag:'textarea', placeholder:'complete address'},
                {title:'Income',field:'income', type:'text', tag:'input', placeholder:'monthly income'},
            ],
            btn:'Next',
            tabName:'Personal Information'
        };
        this['4'] = {
            column:[
                {title:'Username',field:'username', type:'text', tag:'input-required', placeholder:'username'},
                {title:'Password',field:'password', type:'password', tag:'input-required', placeholder:'password'},
                {title:'Confirm Password',field:'confirm_password', type:'password', tag:'input-required', placeholder:'confirm password'},
            ],
            btn:'Verify Mobile Number',
            tabName:'Account Information'
        };
        this['5'] = {
            column:[
                {title:'OTP',field:'otp', type:'text', tag:'input-otp', placeholder:'one time pin'},
            ],
            btn:'Submit',
            tabName:'Confirm OTP'
        };
        this._tab = 0;
        this.currentTab = this._tab + 1;
        this.tab = (n)=>{

            if(n < 0){
                this._tab -= 1;
            } else {
                this._tab += 1;
            };
            this.currentTab = this._tab;
 
            return this._tab;
        };
        this.json = {};
        this.mobile = null;
    },
    validate:{
        async birth_month(e){
            console.log(e);
        }
    },
    handlers:{
        async destroy(skip){

            if(skip == true){
                
            } else {
                this.data._tab = 0;
            };
            
            await this.reset();
        },
        createDateOptions({min, max,push=false}){
            let arr = [];
            let diff = (max - min) + 1;
            for (let i = 0; i < diff; i++){
                let v = String(min + i);
                if(v.length == 1){
                    v = `0${v}`;
                };
                if(push){
                    arr.push(v);
                } else {
                    arr.unshift(v);
                };
            };
            return arr.map(item=>{
                return `<option value=${item}>${item}</option>`
            });
        },

        async isConnected(e){
            let cur_year = (moment().get('year') || 2022);
            let f_year = cur_year - 85;
            let l_year = cur_year - 21;

            this.container.birth_year.innerHTML = await this.fire.createDateOptions({min:f_year, max:l_year});
            this.container.birth_date.innerHTML = await this.fire.createDateOptions({min:1, max:31,push:true});

        
        },
        async birthMonthChanged(e){
            await this.fire('spin',{id:'birthMonthChanged'});
            let year = moment().get('year');
            let date = moment(`${year}-${e.target.value}-1`);
            let end = date.endOf('month').get('date');
            this.fire.changeDate(end);
        },
        async submit(e){
            let json = this.utils.toFormData(e.target, {json:false});
            // this.data.json = Object.assign(this.data.json, json);

            let submit = await this.fire('submitKyc', json);

            if(submit.data){
                await this.fire('success',{message: submit.message});

                this.$router.goTo('kyc_completed',{replace:true});
            };



        },
        async changeDate(end){
            
            this.container.birth_date.innerHTML = await this.fire.createDateOptions({min:1, max:end,push:true});
            await this.fire('spinout','birthMonthChanged');
        },
        async back(){
            await this.fire.destroy(true);
            await this.render({emit:{mode:-1}});
        },
        async plot(data){
            if(!data){
                return null;
            };
            const form = this.$form();

            this.data.controls.forEach(control=>{
                let el = form[control.field];
                if(el && !!data[control.field]){
                    el.value = data[control.field];
                };
            });
        },
        async requestOtp(e){
            await this.fire('requestOtp');
        }
    },
    subscribe:{
        plant:{
            getKyc(){
                return new Promise((res, rej)=>{
                    setTimeout(() => {
                        res(this.data.userKyc);
                    }, 1000);
                });
            },
        }
    },
})