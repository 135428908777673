Cake.create('sidebar','#sidebar',{
    route(e){
        console.log(this.$router);


    },
    handlers:{
        async destroy(){
            await this.reset();
        },
        
    },
    subscribe:{
        header:{
            showSidebar(e){
                console.log(e);
                if(e){
                    setTimeout(()=>{
                        this.render();
                    },300)
                } else {
                    return this.fire.destroy();
                }
            }
        },
    },
});