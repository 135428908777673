Cake.create('header_login','#header_login',{
    root:'.header-account-info',
    renderqueue:[],
    handlers:{
        async destroy(){
            await this.reset();
        },
        isConnected(){
            
        }
    },
    subscribe:{
        header:{
            renderHeaderLogin(){
                this.render();
            },
            removeHeaderLogin(){
                this.fire.destroy();
            },
        }
    },
});