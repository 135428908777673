Cake.create('profile_quick','#profile_quick',{
    root:'.middle-container-top',
    handlers:{
        async destroy(){
            await this.reset();
        },
        async isConnected(){
            let profile = await this.fire('getProfileUser');

            if(profile){
                this.$scope.set('username',profile.username);
                this.$scope.set('balance',profile.balance);
            };
        }
    },
    subscribe:{
        app:{
            async refreshQuickProfile(e){
                let profile = await this.fire('getProfileUser');

                if(profile){
                    this.$scope.set('username',profile.username);
                    this.$scope.set('balance',profile.balance);
                };
            }
        },
        updateBalance:{
            components:['api'],
            handler(e){
                this.$scope.set('balance',e);
            }
        }
    },
});