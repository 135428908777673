Cake.create('skeleton','#skeleton',{
    renderqueue:[],
    handlers:{
        async destroy(e){
            await this.reset();
        },
        isConnected(e){
            let {emit:data} = e;

            let el = this.html.getElement();

            if(el){
                el.style.width = data.width;
                el.style.height = data.height;
            };

            console.log('skeleton is connected');
        }
    },
    subscribe:{
        removeSkeleton:{
            components:['deposit'],
            handler(e){
                return this.fire.destroy();
            }
        },
        renderSkeleton:{
            components:['deposit'],
            handler(e={}){
                if(!(e.root && e.width && e.height)){
                    return;
                };

                return this.render({
                    root:e.root,
                    emit:{
                        width:e.width,
                        height:e.height,
                    }
                });
            }
        }
    },
});