
Cake.create('table', '#table', {
    root:'.profile-action',
    // animate:{
    //     'table':{
    //         render:{keyframes:['appear']}
    //     }   
    // },
    // animate:{
    //     table:{
    //         render:{velocity:{opacity:1}},
    //     },
    // },
    router(){
        this.data.menu = this.$router.name;
        this.data.prev = this.$router.prev && this.$router.prev.name;
    },
    data(){


        this.urls = {
            deposit_history:`${window.config.API_BASE_URL}/deposit/history/paginate`,
            withdraw_history:`${window.config.API_BASE_URL}/withdraw/history/paginate`,
            betting_history:`${window.config.API_BASE_URL}/betting/history/paginate`,
        };


        this.search = {};
        this.tableSettings = {
            persistence:{
                sort:true,
                filter:true,
                // columns:true,
            },
            columnDefaults:{
                tooltip:true,  
            },       //show tool tips on cells
            headerFilterLiveFilterDelay:100, //wait 600ms from last keystroke before triggering filter
            index:'_id', //set the index of the data;
            addRowPos:'top',
            reactiveData:true,
            // clipboard:true,
            // clipboardPasteAction:"replace",
            printAsHtml:true,
            // printStyled: true,
            printRowRange:"selected",
            persistenceID:"breddas",

            pagination:true, //enable pagination
            paginationMode:"remote",
            paginationSize:10,
            paginationSizeSelector:[5, 10, 25, 50],

            filterMode:"remote",
                    //load row data from array
            layout:"fitDataFill",      //fit columns to width of table
            responsiveLayout:"collapse",

            addRowPos:"top",          //when adding a new row, add it to the top of the table
            movableColumns:true,      //allow column order to be changed
            resizableRows:true,       //allow row order to be changed
            // initialSort:[             //set the initial sort order of the data
            //     {column:"_id", dir:"des"},
            // ],
        };

    },
    toggle:{
        activeHistoryTab:{
            ns:`history-tab`, cls:'active', basis:'data-name',
        },
    },
    handlers:{
        
        async destroy(){
            // console.log(this.data.prev);

            if (this.data.table){
                this.data.table.clearData();
                this.data.table.clearHistory();
                this.data.table.clearSort();
                
                this.data.table = null;
            };
            this.data.search = {};
            return this.reset();

            
        },
        clearFilter(){
            this.$scope.set('table_searching', false);
            this.data.search = {};
            this.data.table.setData();
        },
        recalc(){
            if(!this.data.table){
                return;
            };
            this.data.table.setData();
            this.data.table.recalc();
        },
        async isConnected(obj){
            let data = obj.emit && obj.emit.data;
            let name = this.data.menu;
            this.$scope.set('title', this.$router.display);
            if(name == 'deposit_history'){
                this.toggler('activeHistoryTab', 'deposit');
            } else if (name == 'withdraw_history'){
                this.toggler('activeHistoryTab', 'withdraw');
            };
            
            await this.fire.renderTable(data);
        },

        async renderTable(data){
            let settings = this.data.tableSettings;
            let columns = (()=>{
                if(this.$router.name == 'deposit_history'){
                    return [
                        {title:'Date', field:'create_date'},
                        {title:'Type', field:'type'},
                        {title:'Reference', field:'pay_ref'},
                        {title:'Amount', field:'amount'},
                        {title:'Status', field:'status'},
                    ]
                } else {
                    return [
                        {title:'Date', field:'create_date'},
                        {title:'Account Number', field:'settlement_account_number'},
                        {title:'Amount', field:'amount'},
                        {title:'Status', field:'status'},
                    ]
                }
            })();
            if (data){
                let _this = this;
                let config = Object.assign(settings,{
                    columns,
                    data,
                    rowFormatter:function(row){
                        row.getElement().setAttribute('data-_id', row.getData()._id);
                        row.getElement().setAttribute('data-_rev', row.getData()._rev);
                        row.getElement().setAttribute('data-isEditable', row.getData()._isEditable || true);
                    },
                });

                this.data.table = new Tabulator("#dtable", config);

                this.fire('loaded');
            } else {
                let map = this.data.urls;
                let menu = this.data.menu;
                let url = map[menu];
                if (!url){
                    return false;
                };

                // console.log(url);

                let _this = this;
                const config = Object.assign(settings,{
                    columns,
                    rowFormatter:function(row){
                        row.getElement().setAttribute('data-_id', row.getData()._id || row.getData().id);
                        row.getData()._rev && row.getElement().setAttribute('data-_rev', row.getData()._rev);
                        row.getElement().setAttribute('data-isEditable', row.getData()._isEditable || true);
                    },
                }, {
                    ajaxURL:url,
                    ajaxConfig:{
                        mode:"cors", //set request mode to cors
                        credentials: "include", //send cookies with the request from the matching origin
                        method:"GET", //set request type to Position
                        headers: {
                            "Content-type": 'application/json; charset=utf-8', //set specific content type
                            credentials: 'include',
                            "tbl":menu,
                        },
                        dataLoaderLoading:`<div id=dots-container>
                            <div class="dots-2"></div>
                        </div>`,
                    },
                    ajaxResponse:(url, params, response)=>{
                        this.fire('destroySpinner');
                        if (response.status == 0){
                            this.fire('error', response.message)
                        }
                        return response; 
                    },

                    ajaxURLGenerator:(url, config, params)=>{
                        return `${url}?page=${params.page}&size=${params.size}&search=${JSON.stringify({...this.data.dateFilter,...this.data.search,...this.data.bet_filter})}`;
                    },
                });


                this.data.table = new Tabulator("#dtable", config);

                this.data.table && this.data.table.on("rowClick", function(e, _row){
                    // let data = _row.getData();
                    // let row = _row.getElement();
                    // if(e.target.closest('[name=radio-container]')){
                    //     let btn = e.target.closest('.button');
                    //     this.fire('radioClicked', {
                    //         _id:row.dataset._id,
                    //         _rev:row.dataset._rev,
                    //         action:btn.getAttribute('name'),
                    //     });
                    // } else {
                    //     this.fire('tableRowClick',{
                    //         data,root:row,
                    //     });
                    // };
                }.bind(this));
                
                this.data.table.setData();
                this.fire('loaded');
            }
            
        },
        sync(){
            return this.data.table.setData();
        },
        setData({tbl, data}){
            this.data.table.clearData(data);
            this.data.table.setData(data);

        }
    },
    subscribe:{

        getCurrentFilter:{
            components:['api','paginate'],
            handler(e){
                return {...this.data.dateFilter,...this.data.search,...this.data.bet_filter};
            }
        },
        reloadTable:{
            components:['api'],
            handler(e){
                if(this.data.table){
                    this.data.table.setData();
                };
            }
        },
    },
});