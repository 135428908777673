Cake.create('prekyc_form','#prekyc_form',{
    root:'.prekyc-form-container',
    role:'form',
    // watch:true,
    animate:{
        prekyc_form:{
            render:{velocity:{opacity:1}},
        },
    },
    data(){
        this['1'] = {
            column:[
                {title:'Mobile Number',field:'mobile_no', type:'text', tag:'input-required', placeholder:'mobile number ( 0926-xxxxxxx )'},
                {title:'Referral Code',field:'referral_code', type:'text', tag:'input', placeholder:'referral code'},
                {title:'',field:'', type:'', tag:'age-confirmation'}
            ],
            btn:'Register',
        };
        this._tab = 0;
        this.currentTab = this._tab + 1;
        this.tab = (n)=>{

            if(n < 0){
                this._tab -= 1;
            } else {
                this._tab += 1;
            };
            this.currentTab = this._tab;
 
            return this._tab;
        };
        this.json = {};
        this.mobile = null;
    },
    validate:{
        async mobile_no(e){
            let target = e.target;
            let val = target.value;
            if(val.length == 11){
                
            } else {

            };
        }
    },
    handlers:{
        async destroy(){
            await this.reset();
        },
        async isConnected(e){

            let {emit:{mode}} = e;
            let tab = 1;
            let conf = this.data[tab];
            console.log(tab, this.data);

            this.data.controls = conf.column;


            // console.log(conf.column);
            // await this.$scope.set('controls',conf.column);

        },
        async submit(e){
            let json = this.utils.toFormData(e.target, {json:true});
            json.type = 'kyc';
            let otp = await this.fire('requestOtp',json);
            console.log(otp);
            if(otp.message){
                this.fire('info', {message:otp.message});
            } else {
                this.$router.goTo('confirm_otp',{replace:true});
            };
        },
    },
    subscribe:{

    },
})