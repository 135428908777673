Cake.create('api', null, {
    type:'model',
    data(){

    },
    init(){
        this.fire.csrf();
    },
    utils(self){
        const _fetch = (method)=>{

            return (path, obj)=>{
                obj = obj || {};
                let baseUrl = obj.baseUrl;
                if(!obj.baseUrl){
                    if(['user, role, permission','login'].includes(self.data.menu)){
                        baseUrl = window.config.API_BASE_URL;
                    } else{
                        baseUrl = window.config.API_BASE_URL;
                    };
                } else {
                    delete obj.baseUrl;
                };

            
                let config = {
                    method,
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'include', // include, *same-origin, omit, 
                    headers:{
                        timestamp:new Date().getTime(),
                    },
                };
                if (obj && obj.body){
                    config.body = JSON.stringify(obj.body);
                    config.headers = {'Content-Type': 'application/json',};
                };
                if(obj && obj.headers){
                    config.headers = Object.assign(config.headers,obj.headers);
                };

                if(obj && obj.query){
                    console.log(46, this.toUrlSearchParams(obj.query), obj.query);
                    path = `${path}?${this.toUrlSearchParams(obj.query)}`;
                };
                
                return fetch(`${baseUrl}${path}`,config).then(res=>{
                    return res.json();
                }).then(res=>{
                    let sessions = res.sessions;
                    delete res.sessions;
                    for (let key in sessions){
                        // Cookies.set(`${key}-test`,sessions[key], { secure: true, sameSite:'none' });
                    };

                    if(res.status == 2){
                        self.fire('warning', res.message);
                    } else if (res.status == 0){
                        if(res.message == 'jwt expired' && res.type == 'kyc'){
                            self.fire('refreshKyc');
                        } else if(res.message == 'jwt expired' && res.type == 'auth'){
        
                        } else {
                            res && res.message && self.fire('error',{message:res.message});
                        };
                    };
                    return res;
                }).catch(err=>{
                    self.fire('spinout');
                    self.fire('error',{message:err.message});
                });
            };
        };
        this.post = _fetch('POST');
        this.get = _fetch('GET');
        this.delete = _fetch('DELETE');
        this.put = _fetch('PUT');
        this.head = _fetch('HEAD');

        this.getToken = function(){
            let auth = self.$router.auth();
            return auth?auth.token:null;
        }.bind(this);
    },
    router(){
        this.data.menu = this.$router.name;
    },
    handlers:{
        async csrf(){
            await this.fire('spin',{id:'csrf'});
            return this.utils.get(`/csrf`).then(async (result)=>{
                await this.fire('spinout', 'csrf');
                return result;
            });
        },
        async verify(e){
            // await this.fire('spin',{id:'apiverify'});
            return this.utils.get(`/user/verify`).then(async (result)=>{
                // await this.fire('spinout','apiverify');
                if(result && result.is_logout){
                    return {status:0};
                } else {
                    return result || {}
                };
            });
        },
        async login(data){
            console.log(data);
            await this.fire('spin',{id:'login'});
            return this.utils.post(`/user/login`,{
                baseUrl:this.data.webUrl,
                headers:{
                    // 'Authorization':`Bearer ${token}`,
                    'Content-Type':'application/json',
                },
                body:data,
            }).then(res=>{
                this.fire('spinout','login');

                if(res.status == 1){
                    return res.data;
                };
                return null;
            });
        },
        async getAboutUs(){
            await this.fire('spin',{id:'getAboutUs'});
            // console.log('spin about us');
            return this.utils.get(`/content/ref_name/about_us`).then(res=>{
                // console.log('spinout about us');
                this.fire('spinout','getAboutUs');
                
                if(res && res.status == 1){
                    return res.data;
                };
                return null;
            });
        },
        createKyc(data){
            let token = this.utils.getToken();
            this.fire('spin',{id:'createKyc'});
            return this.utils.post(`/userkyc`,{
                baseUrl:this.data.webUrl,
                headers:{
                    // 'Authorization':`Bearer ${token}`,
                    'Content-Type':'application/json',
                },
                body:data,
            }).then(res=>{
                this.fire('spinout','createKyc');
                if(res.status == 1){
                    return res.data;
                };
                return null;
            });
        },
        updateKyc(data){

            this.fire('spin',{id:'updateKyc'});
            return this.utils.put(`/userkyc`,{
                baseUrl:this.data.webUrl,
                headers:{
                    // 'Authorization':`Bearer ${token}`,
                    'Content-Type':'application/json',
                },
                body:data,
            }).then(res=>{
                this.fire('spinout','updateKyc');
                if(res.status == 1){
                    return res.data;
                };
                return null;
            });
        },
        getKyc(mobile_no){
            this.fire('spin',{id:'getKyc'});
            return this.utils.get(`/userkyc/guest/${mobile_no}?kyc=1`,{
                baseUrl:this.data.webUrl,
            }).then(res=>{
                this.fire('spinout','getKyc');
                if(res.status == 1){
                    return res.data;
                };
                return null;
            });
        },
        async submitKyc(data){
            await this.fire('spin',{id:'submitKyc'});
            return this.utils.post(`/userkyc/submit`,{
                baseUrl:this.data.webUrl,
                headers:{
                    // 'Authorization':`Bearer ${token}`,
                    'Content-Type':'application/json',
                },
                body:data,
            }).then(res=>{
                this.fire('spinout','submitKyc');

                if(res.status == 1){
                    return res.data;
                };
                return null;
            });
        },
        async prelogin(data){
            console.log(data);
            await this.fire('spin',{id:'prelogin'});
            return this.utils.post(`/user/prelogin`,{
                baseUrl:this.data.webUrl,
                headers:{
                    // 'Authorization':`Bearer ${token}`,
                    'Content-Type':'application/json',
                },
                body:data,
            }).then(res=>{
                this.fire('spinout','prelogin');

                if(res.status == 1){
                    return res;
                };
                return null;
            });
        },
        async requestOtp(e){
            await this.fire('spin',{id:'requestOtp'});
            return this.utils.post(`/otp`,{
                headers:{
                    'Content-Type':'application/json'
                },
                body:e,
            }).then(res=>{
                this.fire('spinout','requestOtp');
                if(res.status == 1){
                    return res;
                };
                return null;
            });
        },
        async confirmKycOtp(e){
            await this.fire('spin',{id:'confirmKycOtp'});

            return this.utils.get(`/otp/kyc?${this.utils.toUrlSearchParams(e)}`).then(res=>{
                this.fire('spinout','confirmKycOtp');
                if(res.status == 1){
                    return res;
                };
                return null;
            });
        },
        async confirmLoginOtp(e){
            await this.fire('spin',{id:'confirmLoginOtp'});

            return this.utils.get(`/otp/login?${this.utils.toUrlSearchParams(e)}`).then(res=>{
                this.fire('spinout','confirmLoginOtp');
                if(res.status == 1){
                    return res;
                };
                return null;
            });
        },
        async activeGcash(e){
            await this.fire('spin',{id:'activeGcash'});
            return this.utils.get(`/deposit/active-gcash`).then(res=>{
                this.fire('spinout','activeGcash');
                if(res.status == 1){
                    return res;
                };
                return null;
            });
        },
        async logout(){
            await this.fire('spin',{id:'logout'});
            return this.utils.get(`/user/logout`).then(async (result)=>{
                await this.fire('spinout','logout');
                return result;
            });
        },
        async getProfile(){
            await this.fire('spin',{id:'getProfile'});
            return this.utils.get(`/user/profile`).then( (result)=>{
                 this.fire('spinout','getProfile');
                return result;
            });
        },
        async getBalance(){
            await this.fire('spin',{id:'getBalance'});
            return this.utils.get(`/wallet/balance`).then((result)=>{
                this.fire('spinout','getBalance');
                if(result.status){
                    this.fire('updateBalance', result.data.balance);
                };
            });
        },
        async createGcashOrder(e){
            this.utils.post('/deposit/gcash',{
                baseUrl:this.data.gcashUrl,
                query:{
                    amount:e.amount,
                }
            }).then(res=>{
                if(res.status){
                    let {url} = res.data;
                    let a = document.createElement('a');
                    a.href= url;
                    a.target = '_self';
                    document.body.appendChild(a);
                    a.click();  
                };
            });
        },
    },
    subscribe:{
        content_layout_1:{
            getAboutUs(e){
                return this.fire.getAboutUs();
            }
        },
        prekyc_form:{
            requestOtp(e){
                return this.fire.requestOtp(e);
            }
        },
        login_form:{
            prelogin(data){
                return this.fire.prelogin(data, false);
            }
        },
        otp_form:{
            confirmKycOtp(e){
                return this.fire.confirmKycOtp(e, false);
            },
            confirmLoginOtp(e){
                return this.fire.confirmLoginOtp(e, false);
            },
        },
        deposit:{
            activeGcash(e){
                return this.fire.activeGcash(e);
            }
        },
        header:{
            logout(){
                return this.fire.logout();
            }
        },
        getProfile:{
            components:['app'],
            handler(e){
                return this.fire.getProfile(null, false);
            }
        },
        getBalance:{
            components:['socket'],
            handler(e){
                return this.fire.getBalance(null, false);
            }
        },
        deposit_gcash:{
            submitDeposit(e){
                return this.fire.createGcashOrder(e)
            }
        }
    },
});