Cake.create('game_list','#game_list',{
    root:'.game-list-content',
    handlers:{
        destroy(e){
            this.reset();
        },
        isConnected(){
            this.$scope.set('gameList',[
                {img:config.FILE_BASE_URL+'/images/game_list/egames/game-1.png',name:'Da Vincis'},
                {img:config.FILE_BASE_URL+'/images/game_list/egames/game-2.png',name:'Bombuster'},
                {img:config.FILE_BASE_URL+'/images/game_list/egames/game-3.png',name:'Rome'},
                {img:config.FILE_BASE_URL+'/images/game_list/egames/game-4.png',name:'Blood Suckers'},
                {img:config.FILE_BASE_URL+'/images/game_list/egames/game-5.png',name:'Aztec Spins'},
                {img:config.FILE_BASE_URL+'/images/game_list/egames/game-6.png',name:'Cash Volt'},
            ])
        }
    },
    subscribe:{},
});