Cake.create('step','#step',{
    root:'.kyc-step',
    handlers:{
        async destroy(){
            await this.reset();
        },
        isConnected(e){
            let {emit:{perc}} = e;
            // this.$scope.set('perc',perc);

            this.container.progress.style.width = `${perc}%`;
            this.container.progress.textContent = `${perc}%`;
            
        }
    },
    subscribe:{
        kyc_form:{
            renderStep(tab){
                console.log(tab);
                let perc = (((5-(5 - tab)) / 5) * 100) - 2;
                if(tab > 1){
                    return this.fire.destroy().then(()=>{
                        return this.render({emit:{perc}});
                    })
                }
            },
            destroy(){
                this.fire.destroy();
            }
        }
    },
});