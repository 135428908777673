Cake.create('profile','#profile',{
    root:'#main',
    handlers:{
        async destroy(){
            await this.reset();
        },
        async isConnected(){

        }
    },
    subscribe:{

    },
});