Cake.create('deposit_gcash','#deposit_gcash',{
    root:'.profile-action',
    role:'form',
    watch:true,
    router(){
        // console.log(this.$router);
    },
    animate:{
        deposit:{
            render:{velocity:{opacity:1}},
        },
    },
    validate(){},
    handlers:{
        async destroy(){
            await this.fire('removePreview');
            await this.reset();
        },
        async isConnected(){
            let {id, type} = this.$router.state;
            let ref_img_url = "";
            if(id){
                
            };


            await this.$scope.set('controls',[
                {label:'Amount',type:'number',field:'amount',tag:'input-required',placeholder:'amount'},
            ]);


            this.fire('alertInfo',{
                title:'Deposit',
                message:'Minimum deposit of Php 100.00',
            });
        },
        async submit(e){
            let json = this.utils.toFormData(e.target,{json:true});
            

            let created = await this.fire('submitDeposit',json);
            console.log(created);
            return;
            if(created){
                await this.fire('success',{message:created.message});
                this.$router.goTo('deposit_page',{replace:true});
            };

        },
        change(e){
            let [file] = e.target.files;


            this.fire('previewChange',file);
        }
    },
    subscribe:{},
});